import React, { useContext, useEffect } from "react";

import { CurrentUserContext } from "../../providers/auth";

import Layout from "../../components/layout";
import Loading from "../../components/loading";

const SilentLoginPage = () => (
    <Layout checkAuth={false} seoTitle="Loading...">
        <SilentLogin />
    </Layout>
);

export default SilentLoginPage;

const SilentLogin = () => {
    const currentUser = useContext(CurrentUserContext);

    useEffect(() => {
        if (currentUser) {
            currentUser.silentCallback();
        }
    }, [currentUser]);

    return <Loading />;
};
